// @import '../../scss/variables.scss';

// .about-page-component {
//   font-family: $font-sans-serif;
//   font-size: $font-size;

//   display: flex;
//   justify-content: center;
// }

.about-hd {
  text-align: center;
  text-transform: uppercase;
  color: #39f;
  font-size: 28px;
}

.profile-pic {
  width: 200px;
  height: 200px;
  border-radius: 50%
}

.profile:hover {
  color: #000;
  filter:opacity(0.95)
}

.wrapper {
  text-transform: uppercase;
  color: #555;
  cursor: help;
  font-family: "Gill Sans", Impact, sans-serif;
  font-size: 20px;
  margin: 25px;
  padding: 0 50px;
  position: relative;
  text-align: center;
  }
  
  .wrapper .tooltip {
  background: #39f;
  bottom: 100%;
  color: #fff;
  display: block;
  left: -20px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 20px;
  pointer-events: none;
  position: absolute;
  width: 100%;
  -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
     -o-transform: translateY(10px);
      transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
     -o-transition: all .25s ease-out;
      transition: all .25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
     -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  }
  
  /* This bridges the gap so you can mouse into the tooltip without it disappearing */
  .wrapper .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
  }
  
  /* CSS Triangles - see Trevor's post */
  .wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #1496bb 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
  }
  
  .wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
     -o-transform: translateY(0px);
      transform: translateY(0px);
  }
  
  /* IE can just show/hide with no transition */
  .lte8 .wrapper .tooltip {
  display: none;
  }
  
  .lte8 .wrapper:hover .tooltip {
  display: block;
  }
  
  .overlay {
    position: absolute;
    top: 15px;
    bottom: 0;
    left: 20px;
    right: 0;
    height: 200px;
    width: 200px;
    opacity: 0;
    transition: .5s ease;
    background-color: #000;
    border-radius: 50%;
  }
  
  .profile:hover .overlay {
    opacity: 0.5;
  }
