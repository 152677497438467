.case, .personal {
    padding-top: 32px;
}

.personal {
    bottom: 50px;
    right: 50px;
}

#about, #contact {
    padding-bottom: 10px;
}

.case-title, #about a, #contact a, #resume a, .back a:hover, .case-hd {
    text-transform: uppercase;
    color: #39f
}

#about a:hover, #contact a:hover, #resume a:hover {
    color: green;
}