// @import '../../scss/variables.scss';

.doyen-container {
  margin: 70px 40px;
}

button {
  appearance: button;
  backface-visibility: hidden;
  background-color: green;
  border-radius: 6px;
  border-width: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.doyen-container:disabled {
  cursor: default;
}