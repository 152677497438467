html, body, #root, .App, .App-main {
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
}

body, html, #root {
  background: #0F0F0F;
}

.App {
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
}

.App-main {
  display: flex;
  background: #0F0F0F;
  opacity: 0.95;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  font-size: 16px;
  padding: 6px 0;
  background: #5b5d5a;
  width: 100%;
  position: fixed;
  border-radius: 10px 10px 0 0;
  z-index: 1000;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

.App-link {
  color: #61dafb;
}

.type {
  color: green;
  font-family: monospace;
  text-align: left;
  font-size: 1.4em;
  padding: 16px;
}
/* 
.specialties {
  position: absolute;
  top: 196px;
  right: -64px;
  text-align: left;
  text-transform: lowercase;
  transform: rotate(90deg);
  z-index: -1;
}

.specialty {
  margin-top: -8px;
  color: #FBFCF8;
  font-size: 1.7em;
} */

.name {
  position: absolute;
  bottom: -35px;
  left: -4px;
  font-size: 172px;
  letter-spacing: -16px;
  color: #FBFCF8;
}

/* .left-main {
  width: 61.8%;
} */

.right-main {
  width: 38.2%;
  filter: grayscale(100%);
  padding-top: 34px;
}

.right-name-img {
  width: 100%;
}

.main-text {
  text-align: left;
}

.menu-logo {
  position: fixed;
  color:#28282B;
  left: 36px;
  top: 28px;
}

.menu-logo a:visited {
  color:#28282B
}

#tagline {
  text-transform: uppercase;
}

ul {
  list-style-type: none;
}

li {
  margin-bottom: 1em;
}

a, .blue, h4 {
  text-decoration: none;
  color: #39f;
}

a:hover {
  color:green
}

.home {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
}

.logo {
  /* width: 200px;
  filter: grayscale(100%); */
  display: flex;
  align-items: center;
  justify-content: center;
}


.circle{
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
}

.small{
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

.medium{
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large{
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge{
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge{
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1{
  opacity: 0.2;
}
.shade2{
  opacity: 0.5;
}

.shade3{
  opacity: 0.7;
}

.shade4{
  opacity: 0.8;
}

.shade5{
  opacity: 0.9;
}

@keyframes ripple{
  0%{
    transform: scale(0.8);
  }
  
  50%{
    transform: scale(1.2);
  }
  
  100%{
    transform: scale(0.8);
  }
}

/*Page Styling */

@media only screen and (max-width: 600px) {
  .main {
      font-size: 5vw;
  }

  .case-container {
    padding: 50px 25px;
  }

  .case-hd {
    text-align: center;
  }

  .case-section {
    height:fit-content;
  }

  .case-text, .case-list {
      width: 95% !important;
      font-size: 18px;
  }

  #focushome, #dsilogo, #uscishome {
    width: 100%; 
  }

  .case-container, .about, .contact {
    font-size: 18px;
  }

  .about-text {
    width: 100%
  };

  .about {
    display: block;
    margin-top: 50px;
  }
} 

@media only screen and (max-height: 600px) and (orientation: landscape) {
  .case-section:nth-child(1) {
    margin-top: 50px;
  }

  .case-section {
    margin: 0 25px;
  }
}

@media only screen and (min-width: 601px) and (min-height:601px) and (orientation: landscape) {

  .case-text {
    width: 75%;
  }

  .case-section {
    width: 100%;
    height: 100vh;
    background-size:  cover;
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
  }

  .case-section:nth-child(1) {
    background-color: #41424C;
  }

  .case-section:nth-child(2) {
    background-color: #1f1f1f;
  }

  .case-section:nth-child(3) {
    background-color: #0F0F0F;
  }

  .case-section:nth-child(4) {
    background-color: #41424C;
  }

  .case-section:nth-child(5) {
    background-color: #0F0F0F;
  }

  #focushome, #dsilogo, #uscishome {
    width: 25vw; 
  }

  .about, .contact {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-text {
    width: 80%;
    text-align: left;
  };

  .case-container, .about, .contact {
    font-size: 1.4vw;
  }
}

.case-hd {
  font-size: 28px;
  padding: 24px 0;
}

.case-container, .about, .contact {
  position: relative;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  overflow: auto;
  scroll-snap-type: y mandatory;
  color: #FBFCF8;
  background: #0F0F0F;
}