// @import '../../scss/variables.scss';

// .contact-page-component {
//   font-family: $font-sans-serif;
//   font-size: $font-size;

//   display: flex;
//   justify-content: center;
// }

.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.contact-hd {
  padding-bottom: 24px;
  font-size: 28px;
  text-transform: uppercase;
}