// @import '../../scss/variables.scss';

// .home-page-component {
//   font-family: $font-sans-serif;
//   font-size: $font-size;

//   display: flex;
//   justify-content: center;
// }

.type-animate {
    animation: fadeInAnimation 10s 5s normal forwards,splash 1;
    animation-iteration-count: 1;
    opacity: 0;
  }