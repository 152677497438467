@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 15px;
    height: 15px;
    right: 18px;
    top: 9px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #fff
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #3399ff;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #fff;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    margin-top: 6px;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #5b5d5a;
    padding: 1em;
    /* font-size: 1.15em; */
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    height: calc(100vh - 106px);
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    text-align: right;
    text-transform: lowercase;
    font-size: 1.4em;
    padding-top: 10px;
  }
  
  .bm-item a {
    color: #fff
  }

  .bm-item a:hover {
    color: green;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    margin-top: 6px;
  }

  .case, .personal {
    padding-top: 32px;
  }

  .personal {
    bottom: 50px;
    right: 50px;
  }
  
  #about {
    padding-bottom: 10px;
  }

  .screen-size {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color:#fff;
  }

  .folder {
    margin-right:4px;
  }
  
  .back {
    position:fixed;
    right: 48px;
    top: 7px;
  }
  
  .back a {
    color: #fff
  }
  