// @import '../../scss/variables.scss';

// .not-found-page-component {
//   font-family: $font-sans-serif;
//   font-size: $font-size;

//   display: flex;
//   justify-content: center;
// }

.text-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.text {
  font-size: 2em;
  color: white;
}